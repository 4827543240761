<template>
  <v-container fluid class="py-0">
    <split-content>
      <template #auxiliar>
        <v-row>
          <v-col cols="12" class="mt-2 pb-0">
            <v-text-field :label="$t('search')" outlined>
              <template #prepend-inner>
                <v-img max-width="20px" :src="require('@/assets/search.svg')" />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0">
            <h4>{{ $t("filter-by") }}</h4>
          </v-col>
          <v-col v-if="!hideStatus" cols="12" class="py-0">
            <h6>{{ $t("status") }}</h6>
          </v-col>
          <v-col v-if="!hideStatus" cols="12">
            <v-row>
              <v-col v-if="!hideStatus" cols="6" class="py-0">
                <v-checkbox
                  v-model="filters.status"
                  :value="-1"
                  :label="$t('all')"
                  hide-details
                  class="my-0"
                  @click="cleanStatus"
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="filters.status"
                  value="pending"
                  :label="$t('pending')"
                  hide-details
                  class="mt-0"
                  @click="cleanStatus"
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="filters.status"
                  value="paid"
                  :label="$t('paid')"
                  hide-details
                  class="my-0"
                  @click="cleanStatus"
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="filters.status"
                  value="failed"
                  :label="$t('error')"
                  hide-details
                  class="mt-0"
                  @click="cleanStatus"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pb-0 mt-2">
            <h6>{{ $t("date") }}</h6>
          </v-col>
          <v-col cols="6" class="pb-0">
            <v-menu transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.from"
                  :label="$t('from')"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <template #prepend-inner>
                    <v-img max-width="20px" :src="require('@/assets/calendar.svg')" />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="filters.from"> </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" class="pb-0">
            <v-menu transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.to"
                  :label="$t('to')"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <template #prepend-inner>
                    <v-img max-width="20px" :src="require('@/assets/calendar.svg')" />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="filters.to"> </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" class="py-0">
            <h6>{{ $t("payment-plan") }}</h6>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-checkbox
              v-model="filters.type"
              :value="-1"
              :label="$t('all')"
              hide-details
              class="my-0"
              @click="cleanType"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-checkbox
              v-model="filters.type"
              value="unique"
              :label="$t('unique')"
              hide-details
              class="my-0"
              @click="cleanType"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-checkbox
              v-model="filters.type"
              value="scheduled"
              :label="$t('multiple')"
              hide-details
              class="mt-0"
              @click="cleanType"
            />
          </v-col>
        </v-row>
      </template>
      <payments-table :filters="filtersComputed" class="mt-2" />
    </split-content>
  </v-container>
</template>

<script>
import SplitContent from "../components/SplitView";
import PaymentsTable from "./Table";

export default {
  mixins: [],
  props: {
    hideStatus: {
      type: Boolean,
      default: false,
    },
    baseFilters: {
      type: Object,
      default: null,
    },
  },
  components: {
    PaymentsTable,
    SplitContent,
  },
  data: () => ({
    filters: {
      status: [-1],
      type: [-1],
      from: null,
      to: null,
    },
  }),
  computed: {
    filtersComputed: function () {
      const filters = Object.assign({}, this.filters);
      if (filters.status.includes("pending")) {
        filters.status.push("confirmed");
      }
      return filters;
    },
  },
  watch: {},
  created: async function () {
    if (this.baseFilters) {
      if (this.baseFilters.status) {
        this.filters.status = this.baseFilters.status;
      }
    }
  },
  methods: {
    cleanType: function () {
      var index = this.filters.type.indexOf(-1);
      if (index == 0) {
        this.filters.type.splice(index, 1);
      } else if (
        index > -1 ||
        this.filters.type.length == 0 ||
        this.filters.type.length == 2
      ) {
        this.filters.type = [-1];
      }
    },
    cleanStatus: function () {
      if (this.filters.status.length == 1 && this.filters.status.includes("confirmed")) {
        this.filters.status = [];
      }
      var index = this.filters.status.indexOf(-1);
      if (index == 0) {
        this.filters.status.splice(index, 1);
      } else if (
        index > -1 ||
        this.filters.status.length == 0 ||
        this.filters.status.length == 3
      ) {
        this.filters.status = [-1];
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
