<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("archived-payments") }}</h2>
      <p>{{ $t("payments-.sub-heading-archived") }}</p>
    </heading>
    <filtered-table hide-status :baseFilters="{ status: ['failed', 'paid'] }" />
  </v-container>
</template>

<script>
import Heading from "../components/Heading";
import FilteredTable from "./FilteredTable";

export default {
  mixins: [],
  components: {
    Heading,
    FilteredTable,
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created: async function () {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
