<template>
  <div :class="`split-content ${hideAuxiliar ? 'split-content__single' : ''}`">
    <div v-if="!hideAuxiliar">
      <slot name="auxiliar" />
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideAuxiliar: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [],
  components: {},
  computed: {},
  data: () => ({}),
  async created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.split-content {
  display: flex;
  min-height: 80vh;
  &:not(.split-content__single) {
    > div:first-child {
      width: 350px;
      border-right: 1px solid #e6e6e6;
      padding-right: 20px;
    }
    > div:nth-child(2) {
      padding-left: 20px;
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }
  &.split-content__single {
    justify-content: center;
    > div {
      max-width: 400px;
    }
  }
}
</style>
